import React from 'react';
import './error.css';
//PACKAGES

import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
//COMPONENTS
// import Logo from '../../../images/ynb-logo-black.png';
import { styled } from '@mui/material/styles';

const ColorButton = styled(Button)({
  color: 'white !important',
  backgroundColor: '#FF5C5C',
  // border: '1px solid white',
  borderRadius: '30px',
  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
  '&:hover': {
    backgroundColor: '#E25151',
    '@media (hover: none)': {
      backgroundColor: '#E25151',
    },
  },
  marginTop: '60px',
  width: '200px',
});

const Error404 = () => {
  return (
    // <MuiThemeProvider theme={theme}>
    <div className='home'>
      <div className='home-left'>
        <div className='error-left'>
          {/* <img src={Logo} alt='' className='logo' /> */}
          <main
            style={{
              textAlign: 'center',
            }}
          >
            <div className='titulo'>
              <h2>Whooops...</h2>
            </div>
            <div>
              <p style={{ color: 'white' }}>
                Este link não existe! Por favor volte à página de login e insira
                os seus dados para ter acesso.
              </p>
            </div>
            <Link to='/'>
              <ColorButton variant='contained' color='primary' className='btn'>
                Voltar ao Login
              </ColorButton>
            </Link>
          </main>
          <div className='bottom'>
            {/* <p>* Para mais informações, contacte o seu anfitrião.</p> */}
          </div>
        </div>
        {/* <div className='error-right'></div> */}
        {/* <div className='center404'></div> */}
      </div>
    </div>
    // </MuiThemeProvider>
  );
};

export default Error404;
