/* eslint-disable no-useless-concat */
import React from 'react';

// PACKAGES

import { createTheme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { TextField } from '@mui/material';
import { Button, Modal, Fade } from '@mui/material';

const theme = createTheme();

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5] + '!important',
    padding: theme.spacing(3, 3, 5) + '!important',
    maxWidth: '600px',
    width: '600px',
    margin: 15,
  },
  // btnConfirmar: {
  //   width: '100%',
  //   height: '50px',
  //   color: 'white' + '!important',
  //   // backgroundColor: 'rgb(121, 42, 213)' + '!important',
  //   // '&:hover': {
  //   //   backgroundColor: 'rgb(98, 0, 212)' + '!important',
  //   // },
  // },
  btnCancelar: {
    width: '100%',
    height: '50px',
    color: 'rgb(255, 92, 92)' + '!important',
    backgroundColor: 'rgb(255, 92, 92,0.2)' + '!important',
    // '&:hover': {
    //   backgroundColor: 'rgb(121, 42, 213,0.3)' + '!important',
    // },
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-evenly',
  },
};

const ModalEmail = ({
  openEmail,
  handleClose,
  state,
  setState,
  email,
  setEmail,
  user,
  ...props
}) => {
  const { classes } = props;
  const handleChange = (e) => {
    e.preventDefault();
    setEmail({
      ...email,
      [e.target.name]: e.target.value,
    });
  };

  const formattedBody = user && user.cplink;

  return (
    <>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={openEmail}
        onClose={(e) => handleClose('email', e)}
        closeAfterTransition
      >
        <>
          <Fade in={openEmail}>
            <div className={classes.paper}>
              <div className='header-modal'>
                <h2 id='transition-modal-title' className='titulo-modal'>
                  Email
                </h2>
              </div>

              <div>
                <TextField
                  id='email'
                  name='email'
                  label='Email'
                  onChange={handleChange}
                  fullWidth
                  autoComplete='off'
                  className='nome'
                />
              </div>

              <br />
              <br />
              <div className={classes.modalBtns}>
                <a
                  href={
                    `mailto:${email && email.email}?&body= ` +
                    encodeURIComponent(formattedBody)
                  }
                  style={{ textDecoration: 'none', width: '48%' }}
                >
                  <Button
                    style={{
                      width: '100%',
                      height: '50px',
                      color: 'white',
                      // backgroundColor: `${user && user.cor}`,
                      backgroundColor: `rgb(255, 92, 92)`,
                    }}
                  >
                    Confirmar
                  </Button>
                </a>
                <div style={{ textDecoration: 'none', width: '48%' }}>
                  <Button
                    onClick={(e) => handleClose('email', e)}
                    style={{
                      width: '100%',
                      height: '50px',
                      color: 'white',
                      // backgroundColor: `${user && user.cor}`,
                      backgroundColor: `rgb(255, 92, 92)`,
                      opacity: 0.7,
                    }}
                  >
                    Cancelar
                  </Button>
                </div>
              </div>
            </div>
          </Fade>
        </>
      </Modal>
    </>
  );
};

export default withStyles(styles)(ModalEmail);
