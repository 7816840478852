/* eslint-disable eqeqeq */
import React from 'react';
import './App.css';

//PACKAGES
import { Route, Switch, Redirect } from 'react-router-dom';

//Components
import Login from './pages/Global/Login/login';
import Error404 from './pages/Global/Error/error';
import Router from './Router/index';
import RecuperarSenha from './pages/Global/recuperarSenha/recuperarSenha';
import DefinirSenha from './pages/Global/definirSenha/definirSenha';

const App = (props) => {
  return (
    <div className='App'>
      <Switch>
        <Route exact path='/'>
          <Login />
        </Route>
        <Route path='/app'>
          <Router />
        </Route>
        <Route exact path='/recuperarSenha' component={RecuperarSenha} />
        <Route exact path='/definirsenha/:hash' component={DefinirSenha} />
        <Route exact path='/404' component={Error404} />
        <Route path='*'>
          <Redirect to='/404' />
        </Route>
      </Switch>
    </div>
  );
};

export default App;
