import axios from 'axios';
import BASE_URL from '../../../BASE_URL';

const RecuperarSenha =
  (ydata, handleOpenAlert, history, counter, setCounter) =>
  async (dispatch) => {
    await axios
      .post(`${BASE_URL}/frontend/utilizadores/recuperarsenha`, { ydata })
      .then(({ data }) => {
        setCounter({
          ...counter,
          running: true,
        });
      })
      .then(() => handleOpenAlert('green'))
      .catch(({ err }) => {
        handleOpenAlert('yellow');
        console.log(err);
      });
  };
export default RecuperarSenha;
