/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import './login.css';
//PACKAGES
import { useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import {
  Button,
  TextField,
  // createMuiTheme,
  FormControl,
  InputLabel,
  FormHelperText,
  OutlinedInput,
  Snackbar,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import { IconButton, InputAdornment } from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
//IMAGES
// import Logo from '../../../images/ynb-logo-black.png';

//COMPONENTS
import SignIn from '../../../utils/redux/actions/authAction';

const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: '#000 !important' },
  },
  typography: {
    fontFamily: ['poppins', 'sans-serif'].join(','),
  },
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const ColorButton = styled(Button)({
  color: 'white !important',
  backgroundColor: '#FF5C5C',
  // border: '1px solid white',
  borderRadius: '30px',
  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
  '&:hover': {
    backgroundColor: '#E25151',
    '@media (hover: none)': {
      backgroundColor: '#E25151',
    },
  },
  marginTop: '60px',
  width: '200px',
});

const CssTextField = styled(TextField)({
  borderRadius: '30px',
  '& label.Mui-focused': {
    color: '#474a52 !important',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#474a52 !important',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '30px',
    '& input': {
      // paddingLeft: '10px !important',
      backgroundColor: 'white',
      borderRadius: '30px',
    },
    '& fieldset': {
      color: '#474a52 !important',
    },

    '&:hover fieldset': {
      borderColor: '#474a52 !important',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#474a52 !important',
    },
  },
});
const CssOutlinedInput = styled(OutlinedInput)({
  background: 'white',
  borderRadius: '30px',
});

const Login = ({ validateOnChange = true }) => {
  const [errorsUser, setErrorsUser] = useState({});
  const [user, setUser] = useState({
    email: '',
    password: '',
  });
  const [values, setValues] = useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const dispatch = useDispatch();
  const history = useHistory();

  //--------------------------------- validation ------------------------------/
  const validationUser = (fieldValues = user) => {
    const temp = { ...errorsUser };

    if ('email' in fieldValues) {
      // prettier-ignore
      temp.email = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(fieldValues.email) ? '' : 'Coloque um email válido';
    }

    if ('password' in fieldValues)
      temp.password = fieldValues.password ? '' : 'Campo Obrigatório';

    setErrorsUser({
      ...temp,
    });
    if (fieldValues == user) {
      return Object.values(temp).every((x) => x == '');
    }
    return false;
  };
  //--------------------------------------------------------------------------/

  //--------------------------- NOTIFICATION SYSTEM---------------------------//

  const [openSnackGreen, setOpenSnackGreen] = useState(false);
  const [openSnackYellow, setOpenSnackYellow] = useState(false);
  const [openSnackRed, setOpenSnackRed] = useState(false);

  const handleOpenAlert = (btn) => {
    if (btn == 'green') {
      setOpenSnackGreen(true);
    } else if (btn == 'yellow') {
      setOpenSnackYellow(true);
    } else if (btn == 'red') {
      setOpenSnackRed(true);
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackGreen(false);
    setOpenSnackYellow(false);
    setOpenSnackRed(false);
  };

  //------------------------------------------------------------------------//

  const handleChangePassword = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setUser({ ...user, [prop]: event.target.value });

    if (validateOnChange)
      validationUser({ [event.target.name]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleForm = (e) => {
    e.preventDefault();

    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });

    if (validateOnChange) validationUser({ [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateOnChange) validationUser({ [e.target.name]: e.target.value });

    const ydata = [
      {
        user: {
          email: user.email,
          password: user.password,
        },
      },
    ];

    if (validationUser()) {
      dispatch(SignIn(ydata, history, handleOpenAlert));
    }
  };
  const storage = localStorage;

  return (
    <>
      {storage.token ? (
        history.push('/app')
      ) : (
        <>
          <Snackbar
            open={openSnackGreen}
            autoHideDuration={3000}
            onClose={handleCloseAlert}
          >
            <Alert onClose={handleCloseAlert} severity='success'>
              Login efetuado com sucesso!
            </Alert>
          </Snackbar>
          <Snackbar
            open={openSnackYellow}
            autoHideDuration={3000}
            onClose={handleCloseAlert}
          >
            <Alert onClose={handleCloseAlert} severity='warning'>
              Por favor, confirme os seus dados novamente.
            </Alert>
          </Snackbar>
          <Snackbar
            open={openSnackRed}
            autoHideDuration={3000}
            onClose={handleCloseAlert}
          >
            <Alert onClose={handleCloseAlert} severity='error'>
              Houve um problema no servidor.
            </Alert>
          </Snackbar>
          <ThemeProvider theme={defaultMaterialTheme}>
            <div className='home'>
              <div className='home-left'>
                {/* <img src={Logo} alt='' className='logo' /> */}
                <div className='login-form'>
                  <div className='titulo'>
                    {/* <p style={{ fontSize: '30px' }}>Web App</p> */}
                    <p />
                    <h1>Personal Card</h1>
                    <h2 style={{ marginTop: '-20px' }}>Grupo JAF</h2>
                  </div>

                  <form
                    className='loginForm'
                    noValidate
                    autoComplete='off'
                    onSubmit={handleSubmit}
                  >
                    <CssTextField
                      id='email'
                      label='Email'
                      variant='outlined'
                      className='emailInput'
                      value={user.email}
                      name='email'
                      onChange={handleForm}
                      {...(errorsUser.email && {
                        error: true,
                        helperText: errorsUser.email,
                      })}
                    />
                    <FormControl
                      // className={clsx(classes.margin, classes.textField)}
                      variant='outlined'
                      {...(errorsUser.password && {
                        error: true,
                        // , helperText: errors.password
                      })}
                    >
                      <InputLabel htmlFor='outlined-adornment-password'>
                        Password
                      </InputLabel>
                      <CssOutlinedInput
                        id='outlined-adornment-password'
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.password}
                        name='password'
                        onChange={handleChangePassword('password')}
                        endAdornment={
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge='end'
                            >
                              {values.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label='Password'
                      />
                      {errorsUser.password && (
                        <FormHelperText style={{ color: 'red' }}>
                          {errorsUser.password}
                        </FormHelperText>
                      )}
                    </FormControl>
                    {/* <CssTextField
                      id='email'
                      label='Email'
                      variant='outlined'
                      className='emailInput'
                      value={user.email}
                      name='email'
                      onChange={handleForm}
                      {...(errorsUser.email && {
                        error: true,
                        helperText: errorsUser.email,
                      })}
                    /> */}
                  </form>

                  <ColorButton
                    className='btn'
                    onClick={handleSubmit}
                    type='submit'
                  >
                    Entrar{' '}
                  </ColorButton>

                  <Link to='/recuperarSenha' style={{ color: 'white' }}>
                    <p>Recuperar Password</p>
                  </Link>
                </div>
                <div className='bottom'></div>
              </div>
              {/* <div className='home-right'></div> */}
            </div>
          </ThemeProvider>
        </>
      )}
    </>
  );
};

export default Login;
