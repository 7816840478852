/* eslint-disable no-useless-concat */
import React from 'react';

// PACKAGES
import { createTheme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { TextField } from '@mui/material';
import { Button, Modal, Fade } from '@mui/material';

const theme = createTheme();

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5] + '!important',
    padding: theme.spacing(3, 3, 5) + '!important',
    maxWidth: '600px',
    width: '600px',
    margin: 15,
  },
  btnConfirmar: {
    width: '100%',
    height: '50px',
    color: 'white' + '!important',
    backgroundColor: 'rgb(121, 42, 213)' + '!important',
    '&:hover': {
      backgroundColor: 'rgb(98, 0, 212)' + '!important',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'rgb(98, 0, 212)' + '!important',
      },
    },
  },
  btnCancelar: {
    width: '100%',
    height: '50px',
    color: 'rgb(121, 42, 213)' + '!important',
    backgroundColor: 'rgb(121, 42, 213,0.2)' + '!important',
    '&:hover': {
      backgroundColor: 'rgb(121, 42, 213,0.3)' + '!important',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'rgb(98, 0, 212)' + '!important',
      },
    },
  },
  modalBtns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-evenly',
  },
};

const ModalSMS = ({
  openSMS,
  handleClose,
  state,
  setState,
  tel,
  setTel,
  user,
  ...props
}) => {
  const { classes } = props;
  const handleNumbers = (e) => {
    e.preventDefault();

    let value = e.target.value.toString('000000000');
    const re = /^[0-9\b]+$/;
    if (value === '' || re.test(value)) {
      setTel({
        ...tel,
        [e.target.name]: value,
      });
    }
  };

  return (
    <>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={openSMS}
        onClose={(e) => handleClose('sms', e)}
        closeAfterTransition
      >
        <>
          <Fade in={openSMS}>
            <div className={classes.paper}>
              <div className='header-modal'>
                <h2 id='transition-modal-title' className='titulo-modal'>
                  Nº de Telemóvel
                </h2>
              </div>

              <div>
                <TextField
                  id='tel'
                  name='tel'
                  label='Telemóvel'
                  onChange={handleNumbers}
                  fullWidth
                  autoComplete='off'
                  className='nome'
                />
              </div>

              <br />
              <br />
              <div className={classes.modalBtns}>
                <a
                  href={`sms:${tel && tel.tel}?&body=${user && user.cplink}`}
                  style={{ textDecoration: 'none', width: '48%' }}
                >
                  <Button
                    style={{
                      width: '100%',
                      height: '50px',
                      color: 'white',
                      // backgroundColor: `${user && user.cor}`,
                      backgroundColor: `rgb(255, 92, 92)`,
                    }}
                  >
                    Confirmar
                  </Button>
                </a>
                <div style={{ textDecoration: 'none', width: '48%' }}>
                  <Button
                    onClick={(e) => handleClose('sms', e)}
                    style={{
                      width: '100%',
                      height: '50px',
                      color: 'white',
                      // backgroundColor: `${user && user.cor}`,
                      backgroundColor: `rgb(255, 92, 92)`,
                      opacity: 0.7,
                    }}
                  >
                    Cancelar
                  </Button>
                </div>
              </div>
            </div>
          </Fade>
        </>
      </Modal>
    </>
  );
};

export default withStyles(styles)(ModalSMS);
