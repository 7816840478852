/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';

import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: '#000' },
  },
  typography: {
    fontFamily: ['poppins', 'sans-serif'].join(','),
  },
});

const ColorButton = styled(Button)({
  color: 'white !important',
  backgroundColor: '#FF5C5C',
  // border: '1px solid white',
  borderRadius: '30px',
  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
  '&:hover': {
    backgroundColor: '#E25151',
    '@media (hover: none)': {
      backgroundColor: '#E25151',
    },
  },
  marginTop: '60px',
  width: '200px',
});

const SenhaJaAssociada = ({ dispatch }) => {
  const history = useHistory();

  //   useEffect(() => fetch(), []);

  const loginRedirect = (e) => {
    e.preventDefault();
    history.push('/');
    // Limpar aqui a "senha" no Redux
    dispatch({ type: 'CLEAR_DADOS' });
  };

  return (
    <>
      <ThemeProvider theme={defaultMaterialTheme}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '40px',
          }}
        >
          <p style={{ color: 'white' }}>Este link já foi utilizado.</p>
        </div>

        <ColorButton
          variant='contained'
          color='primary'
          className='btn'
          type='submit'
          onClick={loginRedirect}
        >
          Entrar na conta
        </ColorButton>
      </ThemeProvider>
    </>
  );
};

export default SenhaJaAssociada;
