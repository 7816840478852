import axios from 'axios';
import BASE_URL from '../../../BASE_URL';

const DadosSenhaUtilizador = (hash) => async (dispatch) => {
  await axios
    .get(`${BASE_URL}/frontend/signup/${hash}`)
    .then(({ data }) => {
      dispatch({ type: 'GET_DEF_SENHA', payload: data.yData });
    })
    .catch(({ err }) => console.log(err));
};

export default DadosSenhaUtilizador;
