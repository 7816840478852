//PACKAGES
import { Route, Switch, Redirect } from 'react-router-dom';

//COMPONENTS
import PrivateRoute from './PrivateRoute';

//app
import QrCode from '../pages/QrCode/index';

const Router = () => {
  return (
    <>
      <Switch>
        {/* app */}
        <PrivateRoute exact path='/app' component={QrCode} />
        {/* <Route path='/app' component={QrCode} /> */}

        {/* geral */}
        <Route path='*'>
          <Redirect to='/404' />
        </Route>
      </Switch>
    </>
  );
};

export default Router;
