/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import qrCode from '../../images/inesPena.svg';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// MUI
import { Button, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { persistor } from '../../utils/store';
//Components
import ModalSMS from './components/modalSMS';
import ModalEmail from './components/modalEmail';

import { Menu, MenuItem } from '@mui/material';

//Icons
import { FiShare } from 'react-icons/fi';
import { IoMailOutline } from 'react-icons/io5';
import { BiMessageSquareDetail } from 'react-icons/bi';
import { TiUser } from 'react-icons/ti';

const ColorButton = styled(Button)(({ theme }) => ({
  color: '#792ad5',
  backgroundColor: 'white',
  border: '1px solid white',
  borderRadius: '8px',
  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
  '&:hover': {
    backgroundColor: 'white',
    color: '#792ad5',
    // Reset on touch devices, it doesn't add specificity
    '@media (hover: none)': {
      backgroundColor: 'white',
    },
  },
  marginTop: '60px',
}));

// const styles = {
//   square: {
//     background: 'lightgreen',
//   },
// };

const defaultMaterialTheme = createTheme({
  palette: {
    primary: { main: '#000' },
  },
  typography: {
    fontFamily: ['poppins', 'sans-serif'].join(','),
  },
});

const QrCode = (props) => {
  const history = useHistory();
  const [state, setState] = useState();
  const [openSMS, setOpenSMS] = useState(false);
  const [openEmail, setOpenEmail] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);

  const [tel, setTel] = useState({
    tel: '',
  });
  const [email, setEmail] = useState({
    email: '',
  });

  const user = useSelector((state) => state.user.user.user);

  useEffect(() => setState('sms'), []);

  const handleOpen = (btn, e) => {
    e.preventDefault();

    if (btn == 'sms') {
      setOpenSMS(true);
    } else if (btn == 'email') {
      setOpenEmail(true);
    }
  };

  const handleClose = (btn, e) => {
    e.preventDefault();

    if (btn === 'sms') {
      setOpenSMS(false);
    } else if (btn === 'email') {
      setOpenEmail(false);
    }
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleSair = () => {
    // setAnchorEl(null);

    localStorage.removeItem('token');
    localStorage.removeItem('persist:root');
    history.push('/');
    persistor.purge();
  };

  return (
    <div className='App'>
      <ThemeProvider theme={defaultMaterialTheme}>
        <div
          style={{
            width: '100vw',
            position: 'absolute',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-end',
          }}
        >
          <div
            style={{
              marginTop: '3%',
              marginRight: '3%',
            }}
          >
            <IconButton
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleMenu}
              color='inherit'
            >
              <TiUser color='#f7f7f7' />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open1}
              onClose={handleCloseMenu}
            >
              <div>
                <MenuItem onClick={handleSair}>Sair</MenuItem>
              </div>
            </Menu>
          </div>
        </div>
        <header className='App-header'>
          <img src={user && user.qrcode} className='App-logo' alt='logo' />
          {/* Funciona em Android( varios numeros ) e IOS ( apenas o primeiro numero)  */}
          {state == 'sms' && (
            <>
              <Button
                onClick={(e) => handleOpen('sms', e)}
                style={{
                  // color: `${user && user.cor}`,
                  color: '#FF5C5C',
                  backgroundColor: 'white',
                  border: '1px solid white',
                  borderRadius: '50px',
                  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
                  '&:hover': {
                    backgroundColor: 'white',
                    color: `${user && user.cor}`,
                    // Reset on touch devices, it doesn't add specificity
                    '@media (hover: none)': {
                      backgroundColor: 'white',
                    },
                  },
                  minWidth: '140px',
                  marginTop: '60px',
                }}
              >
                <FiShare />
                <span style={{ marginLeft: '10px', fontWeight: '500' }}>
                  Partilhar
                </span>
              </Button>
            </>
          )}
          {state == 'email' && (
            <>
              <Button
                onClick={(e) => handleOpen('email', e)}
                style={{
                  // color: `${user && user.cor}`,
                  color: '#FF5C5C',
                  backgroundColor: 'white',
                  border: '1px solid white',
                  borderRadius: '50px',
                  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
                  '&:hover': {
                    backgroundColor: 'white',
                    color: `${user && user.cor}`,
                    // Reset on touch devices, it doesn't add specificity
                    '@media (hover: none)': {
                      backgroundColor: 'white',
                    },
                  },
                  minWidth: '140px',
                  marginTop: '60px',
                }}
              >
                <FiShare />
                <span style={{ marginLeft: '10px', fontWeight: '500' }}>
                  Partilhar
                </span>
              </Button>
              {/* </a> */}
            </>
          )}

          {/* Funciona em IOS ( varios numeros ) */}
          {/* <a href='sms:/open?addresses=914508280,987654321?&body= body text here '> */}
          <div style={{ marginTop: '20px' }}>
            {' '}
            <Button
              style={
                state === 'sms'
                  ? {
                      color: 'white',
                      // backgroundColor: `${user && user.cor}`,
                      // border: `1px solid ${user && user.cor}`,
                      backgroundColor: `#FF5C5C`,
                      border: `1px solid #FF5C5C`,

                      boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
                      '&:hover': {
                        backgroundColor: 'white',
                        // color: `${user && user.cor}`,
                        color: `#FF5C5C`,
                        // Reset on touch devices, it doesn't add specificity
                        '@media (hover: none)': {
                          backgroundColor: 'white',
                        },
                      },
                      marginTop: '60px',
                      width: '150px',
                      borderRadius: '50px 0px 0px 50px',
                    }
                  : {
                      // color: `${user && user.cor}`,
                      color: `#FF5C5C`,
                      backgroundColor: 'white',
                      border: '1px solid white',

                      boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
                      '&:hover': {
                        backgroundColor: 'white',
                        // color: `${user && user.cor}`,
                        color: `#FF5C5C`,
                        // Reset on touch devices, it doesn't add specificity
                        '@media (hover: none)': {
                          backgroundColor: 'white',
                        },
                      },
                      marginTop: '60px',
                      width: '150px',
                      borderRadius: '50px 0px 0px 50px',
                    }
              }
              onClick={() => setState('sms')}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <BiMessageSquareDetail size='2em' />
                SMS
              </div>
            </Button>
            <Button
              style={
                state === 'email'
                  ? {
                      color: 'white',
                      // backgroundColor: `${user && user.cor}`,
                      // border: `1px solid ${user && user.cor}`,
                      backgroundColor: `#FF5C5C`,
                      border: `1px solid #FF5C5C`,

                      boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
                      '&:hover': {
                        backgroundColor: 'white',
                        // color: `${user && user.cor}`,
                        color: `#FF5C5C`,
                        // Reset on touch devices, it doesn't add specificity
                        '@media (hover: none)': {
                          backgroundColor: 'white',
                        },
                      },
                      marginTop: '60px',
                      width: '150px',
                      borderRadius: '0px 50px 50px 0px',
                    }
                  : {
                      // color: `${user && user.cor}`,
                      color: `#FF5C5C`,
                      backgroundColor: 'white',
                      border: '1px solid white',

                      boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
                      '&:hover': {
                        backgroundColor: 'white',
                        // color: `${user && user.cor}`,
                        color: `#FF5C5C`,
                        // Reset on touch devices, it doesn't add specificity
                        '@media (hover: none)': {
                          backgroundColor: 'white',
                        },
                      },
                      marginTop: '60px',
                      width: '150px',
                      borderRadius: '0px 50px 50px 0px',
                    }
              }
              onClick={() => setState('email')}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <IoMailOutline size='2em' />
                Email
              </div>
            </Button>
          </div>
        </header>

        <ModalSMS
          openSMS={openSMS}
          handleClose={handleClose}
          state={state}
          setState={setState}
          tel={tel}
          setTel={setTel}
          props={props}
          user={user}
        />
        <ModalEmail
          openEmail={openEmail}
          handleClose={handleClose}
          state={state}
          setState={setState}
          email={email}
          setEmail={setEmail}
          props={props}
          user={user}
        />
      </ThemeProvider>
    </div>
  );
};

export default QrCode;
